* {
  box-sizing: border-box;
}

input[type="text"],
select,
textarea {
  width: 100%;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

button[type="reset"] {
  background-color: red;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* button[type="button"] {
  background-color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
} */

label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}

button[type="submit"] {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.List-container {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* Clear floats after the columns */
.col-container {
  display: flex;
  flex-direction: column;
}

.col-75 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 12px;
  align-content: center;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .col-25,
  .col-75,
  input[type="submit"] {
    width: 100%;
    margin-top: 0;
  }
  input[type="clear"] {
    width: 100%;
    margin-top: 0;
  }
}

.Hat-box {
  background-color: transparent;
}

/* This container is needed to position the front and back side */
.Hat-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.hat-box .Hat-box-inner {
  transform: rotateX(180deg);
}

/* Position the front and back side */
.Hat-box-front,
.Hat-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.Hat-box-front {
  background-color: #bbb;
  color: black;
}

/* Style the back side */
.Hat-box-back {
  background-color: dodgerblue;
  color: white;
  transform: rotateX(180deg);
}

.Hat {
  width: inherit;
  margin-top: 30%;
}

.Winter-hat {
  width: inherit;
  margin-top: 30%;
  padding: 100 100 100 100;
}

.Hat:hover {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.25s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
  transform: rotateX(180deg);
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-4px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(4px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-4px, -4px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.Animated {
  display: inline-block;
  border-radius: 4px;
  border: none;
  text-align: center;
  /* font-size: 28px;
  padding: 20px;
  width: 200px; */
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.name span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
  font-size: 25px;
}

.inputText {
  text-align: center;
}

.tip {
  font-style: italic;
}

.row {
  padding-bottom: 15px;
}

.button {
  background-color: #e9c46a;
  color: #fff;
  padding: 15px 15px;
  border-radius: 30px;
  display: inline-block;
  transition: 0.5s;
  cursor: pointer;
}

.button:hover {
  opacity: 0.5;
  background-color: #ef233c;
}

.coinFlip {
  display: flex;
  flex-direction: column;
}

.animate-coin {
  animation: flip 2s 1;
}

.help {
  padding-left: 10px;
  padding-top: 10px;
}

@keyframes flip {
  0% {
    transform: scale3d(1, 1, 1) rotateX(0deg);
  }
  50% {
    transform: scale3d(1, 1, 1) rotateX(3600deg);
  }
  100% {
    transform: scale3d(1, 1, 1) rotateX(7200deg);
  }
}

#coin {
  position: relative;
  margin: 0 auto;
  width: 100px;
  height: 100px;
  cursor: pointer;
}
#coin div {
  width: 100%;
  height: 100%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: inset 0 0 45px rgba(255, 255, 255, 0.3),
    0 12px 20px -10px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: inset 0 0 45px rgba(255, 255, 255, 0.3),
    0 12px 20px -10px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 0 45px rgba(255, 255, 255, 0.3),
    0 12px 20px -10px rgba(0, 0, 0, 0.4);
}
.side-a {
  /* background-color: #bb0000; */
  background-image: url("dogeFrontClean.jpeg");
}
.side-b {
  /* background-color: #3e3e3e; */
  background-image: url("dogeBackClean.jpeg");
}

#coin {
  transition: -webkit-transform 1s ease-in;
  transform-style: preserve-3d;
}
#coin div {
  position: absolute;
  backface-visibility: hidden;
}
.side-a {
  z-index: 100;
}
.side-b {
  transform: rotateY(-180deg);
}

#coin.heads {
  -webkit-animation: flipHeads 3s ease-out forwards;
  -moz-animation: flipHeads 3s ease-out forwards;
  -o-animation: flipHeads 3s ease-out forwards;
  animation: flipHeads 3s ease-out forwards;
}
#coin.tails {
  -webkit-animation: flipTails 3s ease-out forwards;
  -moz-animation: flipTails 3s ease-out forwards;
  -o-animation: flipTails 3s ease-out forwards;
  animation: flipTails 3s ease-out forwards;
}

@keyframes flipHeads {
  from {
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
    transform: rotateY(0);
  }
  to {
    -webkit-transform: rotateY(1800deg);
    -moz-transform: rotateY(1800deg);
    transform: rotateY(1800deg);
  }
}
@keyframes flipTails {
  from {
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
    transform: rotateY(0);
  }
  to {
    -webkit-transform: rotateY(1980deg);
    -moz-transform: rotateY(1980deg);
    transform: rotateY(1980deg);
  }
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
